export const VERSION = 5

export const AJAX_RETRY_SECONDS = (10 * 1000)

//export const AJAX_URL = 'https://build.test/app.php'
export const AJAX_URL = 'https://dev.stemaccelerators.co.uk/wp-json/drp-to-wp/v2/workshop-feedback'

export const EMOTICON_STATES = [
    {
        'title' : 'NO',
        svg : {__html: '<svg id="svg__feedback-no" viewBox="0 0 118 118" width="100%" height="100%"><g transform="matrix(-1 0 0 1 109 7)"><g filter="url(#a)"><circle id="b" cx="50" cy="50" r="50"></circle></g><g fill="inherit"><circle id="b" cx="50" cy="50" r="50"></circle></g><circle cx="30" cy="44" r="4" fill="#004D27"></circle><circle cx="70" cy="44" r="4" fill="#004D27"></circle><path stroke="#004D27" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M33 72.224c1.52-4.309 4.011-7.623 7.471-9.944C51.251 55.051 62.8 60.391 66 72.224"></path></g></svg>'}
    },
    {
        'title' : 'NOT REALLY',
        svg : {__html: '<svg id="svg__feedback-not-really" viewBox="0 0 118 118" width="100%" height="100%"><g transform="matrix(-1 0 0 1 109 7)"><g filter="url(#a)"><circle id="b" cx="50" cy="50" r="50"></circle></g><g fill="inherit"><circle id="b" cx="50" cy="50" r="50"></circle></g><circle cx="30" cy="44" r="4" fill="#004D27"></circle><circle cx="70" cy="44" r="4" fill="#004D27"></circle><path stroke="#004D27" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M35 69c10.465-6.667 20.798-6.667 31 0"></path></g></svg>'}
    },
    {
        'title' : 'NOT SURE',
        svg : {__html: '<svg id="svg__feedback-not-sure" viewBox="0 0 118 118" width="100%" height="100%"><g transform="matrix(-1 0 0 1 109 7)"><g filter="url(#a)"><circle id="b" cx="50" cy="50" r="50"></circle></g><g fill="inherit"><circle id="b" cx="50" cy="50" r="50"></circle></g><circle cx="30" cy="44" r="4" fill="#004D27"></circle><circle cx="70" cy="44" r="4" fill="#004D27"></circle><path stroke="#004D27" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M35 69h31"></path></g></svg>'}
    },
    {
        'title' : 'KIND OF',
        svg : {__html: '<svg id="svg__feedback-kind-of" viewBox="0 0 118 118" width="100%" height="100%"><g transform="matrix(-1 0 0 1 109 7)"><g fill="inherit"><circle id="a" cx="50" cy="50" r="50"></circle></g><circle cx="30" cy="44" r="4" fill="#004D27"></circle><circle cx="70" cy="44" r="4" fill="#004D27"></circle><path stroke="#004D27" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M66 64c-10.465 6.667-20.798 6.667-31 0"></path></g></svg>'}
    },
    {
        'title' : 'YES',
        svg : {__html: '<svg id="svg__feedback-yes" viewBox="0 0 118 118" width="100%" height="100%"><g transform="matrix(-1 0 0 1 109 7)"><g filter="url(#a)"><circle id="b" cx="50" cy="50" r="50"></circle></g><g fill="inherit"><circle id="b" cx="50" cy="50" r="50"></circle></g><circle cx="30" cy="44" r="4" fill="#004D27"></circle><circle cx="70" cy="44" r="4" fill="#004D27"></circle><path stroke="#004D27" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M31.153 62.656h37.694c-4.79 9.005-11.169 13.507-19.135 13.507-7.965 0-14.152-4.502-18.559-13.507z"></path></g></svg>'}
    },
]

export const DEFAULT_FORM_DATA = {
    whichWorkshop: {
        question: 'Which workshop did you take part in today?',
            answer: ''
    },
    gender: {
        question: 'What is your Gender?',
        answer: '',
        options: [
            {
                'title' : 'Female',
                svg : {__html: '<svg id="svg__feedback-yes" viewBox="0 0 118 118" width="100%" height="100%"><g transform="matrix(-1 0 0 1 109 7)"><g filter="url(#a)"><circle id="b" cx="50" cy="50" r="50"></circle></g><g fill="inherit"><circle id="b" cx="50" cy="50" r="50"></circle></g><circle cx="30" cy="44" r="4" fill="#004D27"></circle><circle cx="70" cy="44" r="4" fill="#004D27"></circle><path stroke="#004D27" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M31.153 62.656h37.694c-4.79 9.005-11.169 13.507-19.135 13.507-7.965 0-14.152-4.502-18.559-13.507z"></path></g></svg>'}
            },
            {
                'title' : 'Male',
                svg : {__html: '<svg id="svg__feedback-yes" viewBox="0 0 118 118" width="100%" height="100%"><g transform="matrix(-1 0 0 1 109 7)"><g filter="url(#a)"><circle id="b" cx="50" cy="50" r="50"></circle></g><g fill="inherit"><circle id="b" cx="50" cy="50" r="50"></circle></g><circle cx="30" cy="44" r="4" fill="#004D27"></circle><circle cx="70" cy="44" r="4" fill="#004D27"></circle><path stroke="#004D27" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M31.153 62.656h37.694c-4.79 9.005-11.169 13.507-19.135 13.507-7.965 0-14.152-4.502-18.559-13.507z"></path></g></svg>'}
            },
            {
                'title' : 'Other',
                svg : {__html: '<svg id="svg__feedback-yes" viewBox="0 0 118 118" width="100%" height="100%"><g transform="matrix(-1 0 0 1 109 7)"><g filter="url(#a)"><circle id="b" cx="50" cy="50" r="50"></circle></g><g fill="inherit"><circle id="b" cx="50" cy="50" r="50"></circle></g><circle cx="30" cy="44" r="4" fill="#004D27"></circle><circle cx="70" cy="44" r="4" fill="#004D27"></circle><path stroke="#004D27" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M31.153 62.656h37.694c-4.79 9.005-11.169 13.507-19.135 13.507-7.965 0-14.152-4.502-18.559-13.507z"></path></g></svg>'}
            },
            {
                'title' : 'Prefer not to say',
                svg : {__html: '<svg id="svg__feedback-yes" viewBox="0 0 118 118" width="100%" height="100%"><g transform="matrix(-1 0 0 1 109 7)"><g filter="url(#a)"><circle id="b" cx="50" cy="50" r="50"></circle></g><g fill="inherit"><circle id="b" cx="50" cy="50" r="50"></circle></g><circle cx="30" cy="44" r="4" fill="#004D27"></circle><circle cx="70" cy="44" r="4" fill="#004D27"></circle><path stroke="#004D27" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M31.153 62.656h37.694c-4.79 9.005-11.169 13.507-19.135 13.507-7.965 0-14.152-4.502-18.559-13.507z"></path></g></svg>'}
            },
        ]
    },
    age: {
        question: 'How old are you?',
        answer: ''
    },
    howMuchDidYouKnow: {
        question: 'How much did you know about the Army before the presentation?',
        answer: '',
        options: [
            {
                'title' : 'Nothing',
                svg : {__html: '<svg id="svg__feedback-no" viewBox="0 0 118 118" width="100%" height="100%"><g transform="matrix(-1 0 0 1 109 7)"><g filter="url(#a)"><circle id="b" cx="50" cy="50" r="50"></circle></g><g fill="inherit"><circle id="b" cx="50" cy="50" r="50"></circle></g><circle cx="30" cy="44" r="4" fill="#004D27"></circle><circle cx="70" cy="44" r="4" fill="#004D27"></circle><path stroke="#004D27" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M33 72.224c1.52-4.309 4.011-7.623 7.471-9.944C51.251 55.051 62.8 60.391 66 72.224"></path></g></svg>'}
            },
            {
                'title' : 'A Little',
                svg : {__html: '<svg id="svg__feedback-not-sure" viewBox="0 0 118 118" width="100%" height="100%"><g transform="matrix(-1 0 0 1 109 7)"><g filter="url(#a)"><circle id="b" cx="50" cy="50" r="50"></circle></g><g fill="inherit"><circle id="b" cx="50" cy="50" r="50"></circle></g><circle cx="30" cy="44" r="4" fill="#004D27"></circle><circle cx="70" cy="44" r="4" fill="#004D27"></circle><path stroke="#004D27" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M35 69h31"></path></g></svg>'}
            },
            {
                'title' : 'A lot',
                svg : {__html: '<svg id="svg__feedback-yes" viewBox="0 0 118 118" width="100%" height="100%"><g transform="matrix(-1 0 0 1 109 7)"><g filter="url(#a)"><circle id="b" cx="50" cy="50" r="50"></circle></g><g fill="inherit"><circle id="b" cx="50" cy="50" r="50"></circle></g><circle cx="30" cy="44" r="4" fill="#004D27"></circle><circle cx="70" cy="44" r="4" fill="#004D27"></circle><path stroke="#004D27" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M31.153 62.656h37.694c-4.79 9.005-11.169 13.507-19.135 13.507-7.965 0-14.152-4.502-18.559-13.507z"></path></g></svg>'}
            },
        ]
    },
    enjoyThePresentation: {
        question: 'Did you enjoy the presentation',
        answer: ''
    },
    learnAboutTheArmy: {
        question: 'What did you learn about the Army',
        answer: ''
    },
    wasTheWorkshopFun: {
        question: 'Was the workshop fun?',
        answer: ''
    },
    learnAboutStem: {
        question: 'What did you learn about STEM?',
        answer: ''
    },
    visitAgain: {
        question: 'Would you like the army to visit your school again?',
        answer: ''
    },
    considerChoosingStemSubjects: {
        question: 'Would you consider choosing to study STEM subjects in the future',
        answer: ''
    },
    feedback: ''
}

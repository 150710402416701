import React, { Component } from 'react'

import './App.scss';
import * as constants from  './constants'
import Emoticon from './emoticon'

class emoticonSelection extends Component {

    handleClickEmoticonSelected = (selectedString) => {
        this.props.saveEmoticonAnswer(this.props.refName, selectedString)
    }

    render() {

        const options = this.props.options ? this.props.options : constants.EMOTICON_STATES;

        return (
            <div className='green-container' >
                <div className='number-value' >{this.props.displayNumber}</div>

                <h2>{this.props.children}</h2>

                <div className='faces-container'>
                    {
                        options.map((emoticon,i) =>
                            <Emoticon
                                active={this.props.selectedString === emoticon.title}
                                key={i}
                                handleClickEmoticonSelected={this.handleClickEmoticonSelected}
                                index={i}
                                selectedString={this.props.selectedString}
                                svg={emoticon.svg}
                                title={emoticon.title}
                            />
                         )
                    }
                </div>

            </div>
        );
    }
}

export default emoticonSelection;

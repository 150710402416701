import React, { Component } from 'react'
import classNames from 'classnames/bind';

class emoticon extends Component {

    render() {
        let index = this.props.index

        return (
            <div
                className={classNames('face-cell', 'face-cell-' + index, this.props.active ? 'active' : '')}
                onClick={() => { this.props.handleClickEmoticonSelected(this.props.title)}}
            >
                <div dangerouslySetInnerHTML={this.props.svg} />
                {this.props.title}
            </div>
        );
    }
}

export default emoticon;

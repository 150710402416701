import React, { Component } from 'react'
import * as constants from  './constants'
import axios from 'axios'

import logos from './images/logos.png'
import {getTransactions, addTransaction, numOfTransactions, clearLocalStorage} from './Transactions'
import cloneDeep from 'lodash/cloneDeep'
import EmoticonSelection from './emoticonSelection'

import './App.scss';

class App extends Component {

  state = {
      setupComplete: false,
      school : '',
      transactionsQty : 0,
      activityIndicator : true,
      showThankYouPage : false,
      errorMessages : [],
      formData : {}, // init at start & on form reset
  }

  componentWillMount() {
      this.ajaxTransactions()

      this.setState({transactionsQty : numOfTransactions()})
      this.initFormDataState()
  }

  initFormDataState() {
    let formData = cloneDeep(constants.DEFAULT_FORM_DATA)
    this.setState({formData})
  }

  saveFormAnswer = (e) => {
    let formData = {...this.state.formData}
    formData[e.target.name].answer = e.target.value
    this.setState({formData});
  }

    saveEmoticonAnswer = (refName, selectedString) => {
        let formData = {...this.state.formData}
        formData[refName].answer = selectedString
        this.setState({formData});
    }

  saveDropDownAnswer = (e) => {
      let formData = {...this.state.formData}
      formData.whichWorkshop.answer = e.target.value
      this.setState({formData})
  }

  submitForm = () => {
      let errorMessages = []

      if (this.state.formData.whichWorkshop.answer === '') {
          errorMessages.push("You must enter your workshop");
      }

      if (
        this.state.formData.gender === '' ||
        this.state.formData.age === '' ||
        this.state.formData.howMuchDidYouKnow === '' ||
        this.state.formData.enjoyThePresentation === '' ||
        this.state.formData.learnAboutTheArmy === '' ||
        this.state.formData.wasTheWorkshopFun === '' ||
        this.state.formData.learnAboutStem === '' ||
        this.state.formData.visitAgain === '' ||
        this.state.formData.considerChoosingStemSubjects === ''
      ) {
          errorMessages.push("You must respond to all of the questions");
      }

      this.setState({errorMessages})

      if (errorMessages.length === 0) {
          let formData = {...this.state.formData}
          formData.timeStamp = Math.round((new Date()).getTime() / 1000)
          formData.school = this.state.school
          addTransaction(formData)
          this.setState({transactionsQty : numOfTransactions()})

          clearTimeout(window.timer)
          this.ajaxTransactions()

          this.setState({showThankYouPage : true})
          setTimeout(() => { this.showRegPage() } , 3000)
          this.initFormDataState()
      }
      window.scrollTo(0, 0)
  }

  ajaxTransactions = () => {
      if (numOfTransactions() > 0) {
          this.setState({activityIndicator : !this.state.activityIndicator})

          let transactions = getTransactions()

          axios.post(constants.AJAX_URL, {transactions : JSON.stringify(transactions)})
              .then((response) => {
                  if (response.data.error === false) {
                      clearLocalStorage()
                      this.setState({transactionsQty : numOfTransactions()})
                  } else {
                      window.timer = setTimeout(this.ajaxTransactions, constants.AJAX_RETRY_SECONDS)
                  }
              })
              .catch((error) => {
                  window.timer = setTimeout(this.ajaxTransactions, constants.AJAX_RETRY_SECONDS)
              })
      }
      this.setState({transactionsQty : numOfTransactions()})
  }

  showRegPage = () => {
      this.setState({showThankYouPage : false})
  }

  handleSchoolNameChange = (event) => {
      this.setState({school: event.target.value})
  }

  submitSchoolName = (event) => {
      if (this.state.school === '') {
          alert('You must enter the school name to continue')
          return
      }
      this.setState({setupComplete : true})
      window.scrollTo(0, 0)
  }

  handleFeedbackChange = (event) => {
      let formData = {...this.state.formData}
      formData.feedback = event.target.value
      this.setState({formData})
  }

  render() {
    const formData = this.state.formData

    let unsyncedTransactionsDisplay = '';
    if (this.state.transactionsQty) {
        unsyncedTransactionsDisplay = '(' + this.state.transactionsQty + ')';
    }

    return (
      <div>
        <div className='info'>
          { this.state.school }: v{ constants.VERSION } { unsyncedTransactionsDisplay } { this.state.activityIndicator ? "." : '' }
        </div>

        { this.state.setupComplete === false &&
          <div className="page-setup">
              <img alt='logos' className="logos" src={logos} />
              <h1>Setup</h1>

              <h2>Version Check</h2>
              <p>
                Version: { constants.VERSION }<br />
                Please ensure that the app is the latest version as communicated to you. If it needs updating please follow the instructions in the tablet setup guide.
              </p>

              <h2>Data synced</h2>
              { this.state.transactionsQty === 1 &&
                <div>
                  There is <strong>{this.state.transactionsQty}</strong> unsynced transaction on this tablet. If you connect to the internet the data will automatically upload.
                </div>
              }

              { this.state.transactionsQty > 1 &&
                <div>
                  There are <strong>{this.state.transactionsQty}</strong> unsynced transactions on this tablet.  If you connect to the internet the data will automatically upload.
                </div>
              }

              { this.state.transactionsQty === 0 &&
                <div>
                  All OK.
                </div>
              }

              <h2>School name</h2>
              <p>Please enter the school name</p>
              <input
                  type="text"
                  className="setup-input"
                  value={this.state.school}
                  onChange={this.handleSchoolNameChange}
              />

              <br />
              <br />
              <button onClick={this.submitSchoolName}>
                Enter
              </button>
            </div>
          }

          { (this.state.setupComplete && this.state.showThankYouPage) &&
            <div className="reg-page feedback-thankyou">
              <img alt='logos' className="logos" src={logos} />
              <div className="yellow-title clear-fix">
                <h1>Feedback</h1>
              </div>

              <div className='thankyou-container' >
                <h2>Thank you for your feedback.</h2>
              </div>
            </div>
          }

          { (this.state.setupComplete && !this.state.showThankYouPage) &&
            <div>
              <div className="reg-page">
                <img alt='logos' className="logos" src={logos} />

                <div className="yellow-title">
                  <h1>Feedback</h1>
                </div>

                { this.state.errorMessages.length > 0 && (
                  <div className='error-message' >
                    { this.state.errorMessages.map((error, key) =>
                      <div key={key} >{error}</div>
                    )}
                  </div>
                )}

                <div className='green-container' >
                  <div className='number-value' >1</div>

                  <h2>{formData.whichWorkshop.question}</h2>

                  <select onChange={(e) => this.saveDropDownAnswer(e)}>
                    <option value="">Select from list</option>
                    <option value="Operation Disaster Relief">OPERATION DISASTER RELIEF</option>
                    <option value="OPERATION FRONTLINE SCIENCE">OPERATION FRONTLINE SCIENCE</option>
                    <option value="OPERATION ROCKET CAR">OPERATION ROCKET CAR</option>
                    <option value="WHO'S THE HACKER">WHO'S THE HACKER</option>
                  </select>
                </div>

                  <EmoticonSelection
                      saveEmoticonAnswer={this.saveEmoticonAnswer}
                      displayNumber='2'
                      selectedString={formData.gender.answer}
                      options={formData.gender.options}
                      refName='gender'
                  >
                      { formData.gender.question }
                  </EmoticonSelection>

                <div className='green-container' >
                  <div className='number-value' >3</div>

                  <h2>{formData.age.question}</h2>

                  <input
                    type="text"
                    name="age"
                    value={formData.age.answer}
                    onChange={(e) => this.saveFormAnswer(e)}
                  />
                </div>

                  <EmoticonSelection
                      saveEmoticonAnswer={this.saveEmoticonAnswer}
                      displayNumber='4'
                      selectedString={formData.howMuchDidYouKnow.answer}
                      options={formData.howMuchDidYouKnow.options}
                      refName='howMuchDidYouKnow'
                  >
                      { formData.howMuchDidYouKnow.question }
                  </EmoticonSelection>

                  <EmoticonSelection
                      saveEmoticonAnswer={this.saveEmoticonAnswer}
                      displayNumber='5'
                      selectedString={formData.enjoyThePresentation.answer}
                      refName='enjoyThePresentation'
                  >
                      { formData.enjoyThePresentation.question }
                  </EmoticonSelection>

                <div className='green-container'>
                  <div className='number-value' >6</div>

                  <h2>{formData.learnAboutTheArmy.question}</h2>

                  <div>
                    <input
                      type="text"
                      name="learnAboutTheArmy"
                      value={formData.learnAboutTheArmy.answer}
                      onChange={(e) => this.saveFormAnswer(e)}
                    />
                  </div>
                </div>

                  <EmoticonSelection
                      saveEmoticonAnswer={this.saveEmoticonAnswer}
                      displayNumber='7'
                      selectedString={formData.wasTheWorkshopFun.answer}
                      refName='wasTheWorkshopFun'
                  >
                      { formData.wasTheWorkshopFun.question }
                  </EmoticonSelection>

                <div className='green-container'>
                  <div className='number-value' >8</div>

                  <h2>{formData.learnAboutStem.question}</h2>

                  <div>
                    <input
                      type="text"
                      name="learnAboutStem"
                      value={formData.learnAboutStem.answer}
                      onChange={(e) => this.saveFormAnswer(e)}
                    />
                  </div>
                </div>

                  <EmoticonSelection
                      saveEmoticonAnswer={this.saveEmoticonAnswer}
                      displayNumber='9'
                      selectedString={formData.visitAgain.answer}
                      refName='visitAgain'
                  >
                      { formData.visitAgain.question }
                  </EmoticonSelection>

                  <EmoticonSelection
                      saveEmoticonAnswer={this.saveEmoticonAnswer}
                      displayNumber='10'
                      selectedString={formData.considerChoosingStemSubjects.answer}
                      refName='considerChoosingStemSubjects'
                  >
                      { formData.considerChoosingStemSubjects.question }
                  </EmoticonSelection>
              </div>

              <div className='grad' ></div>

              <div className='thanks-section'>
                <h2>Thanks!</h2>
                <h3>IF YOU HAVE ANY OTHER FEEDBACK, PLEASE ADD IT BELOW! </h3>

                <p>Your feedback (200 characters or less)</p>

                <textarea value={this.state.formData.feedback} onChange={this.handleFeedbackChange} />

                <button onClick={() => { this.submitForm() }} >SUBMIT YOUR FEEDBACK</button>
              </div>
            </div>
          }
      </div>
    );
  }
}

export default App;

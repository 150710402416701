export function getTransactions () {
    let transactions = []
    if (localStorage.getItem('stemtransactions') != null) {
        transactions = JSON.parse(localStorage.getItem('stemtransactions'))
    }
    return transactions
}

export function addTransaction (transactionObject) {
    let transactions = getTransactions()
    transactions.push(transactionObject);
    localStorage.setItem('stemtransactions' , JSON.stringify(transactions));
}

export function numOfTransactions (transactionObject) {
    let transactions = getTransactions()
    return transactions.length;
}

export function clearLocalStorage (transactionObject) {
    localStorage.removeItem("stemtransactions");
}
